import * as React from 'react';
import { ISampleGridProps } from './ISampleGridProps';
import styles from './SampleGrid.module.scss';
import { Sample } from '../../models/Sample';

export const SampleGrid: React.FunctionComponent<ISampleGridProps> = (props: ISampleGridProps) => {
    const rows = Math.sqrt(props.BoxSize);
    const columns = Math.sqrt(props.BoxSize);
    let items = [];
    for (let r = 0; r < rows; r++) {
        let rowLetter = String.fromCharCode(65 + (r > 7 ? r + 1 : r));
        for (let c = 0; c < columns; c++) {
            let sample = props.Samples.filter((x: Sample) => x.locationPos == rowLetter + (c + 1))[0] as Sample;
            if (sample)
                items.push(
                    <div key={rowLetter + (c + 1)} className={sample.isIndivuTypeReserved ? styles.gridItemWithIndivuTypeSample : sample.isIndivumedTherapeutics ? styles.gridItemWithTherapeuticsSample : styles.gridItemWithSample}>
                        {rowLetter + (c + 1)}
                        <br />
                        {sample.sampleIdentifier}
                        <span className={styles.gridItemTooltip}>{sample.sampleIdentifier}</span>
                    </div>
                );
            // r*columns+c+1
            else
                items.push(
                    <div key={rowLetter + (c + 1)} className={styles.gridItem}>
                        {rowLetter + (c + 1)}
                    </div>
                ); // r*columns+c+1
        }
    }

    let className = '';
    switch (props.BoxSize) {
        case 100:
            className = styles.gridContainer100;
            break;
        case 81:
            className = styles.gridContainer81;
            break;
        case 36:
            className = styles.gridContainer36;
            break;
    }
    return <div className={className}>{items}</div>;
};
