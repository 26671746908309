import * as React from 'react';
import styles from './SampleInventory.module.scss';
import { ISampleInventoryState } from './ISampleInventoryState';
import { SampleGrid } from './SampleGrid/SampleGrid';
import axios from 'axios';
import { Container } from '../models/Container';
import { Rack } from '../models/Rack';
import { Box } from '../models/Box';
import { StorageSite } from '../models/StorageSite';
import { ContainerType } from '../models/ContainerType';
import { Checkbox, Select } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { SampleScanningGrid } from './SampleScanning/SampleScanningGrid';
import BackendApi from '../services/Api';
import { useApplicationStore } from '../stores/Application';

const SAMPLE_INVENTORY_STORE_PREFIX: string = 'sample-inventory-';

export default class SampleInventory extends React.Component<{}, ISampleInventoryState> {
    public componentDidMount() {
        this.GetStorageSites();
        this.GetContainerTypes();
        const storageSite = localStorage.getItem(SAMPLE_INVENTORY_STORE_PREFIX + 'storageSiteId');
        const containerType = localStorage.getItem(SAMPLE_INVENTORY_STORE_PREFIX + 'containerTypeId');
        this.SetContainers(containerType ? +containerType : undefined, storageSite ? +storageSite : undefined, !(storageSite && containerType));
    }
    public storageSiteChangedHandler = (value: string): void => {
        localStorage.setItem(SAMPLE_INVENTORY_STORE_PREFIX + 'storageSiteId', value);
        this.SetContainers(this.state.SelectedContainerTypeId, +value, this.state.HideLocationAndContainerTypeDropdowns);
        this.setState({ SelectedStorageSiteId: +value });
    };
    public containerTypeChangedHandler = (value: string): void => {
        localStorage.setItem(SAMPLE_INVENTORY_STORE_PREFIX + 'containerTypeId', value);
        this.SetContainers(+value, this.state.SelectedStorageSiteId, this.state.HideLocationAndContainerTypeDropdowns);
        this.setState({ SelectedContainerTypeId: +value });
    };
    public SetContainers = async (containerTypeId: number | undefined, storageSiteId: number | undefined, hideDropdowns: boolean): Promise<void> => {
        this.setState({
            Containers: [],
            Racks: null,
            Boxes: [],
            Samples: null,
            SelectedBox: undefined,
            SelectedRackId: undefined,
            SelectedBoxId: undefined,
            SelectedContainerId: undefined,
            SelectedContainerTypeId: containerTypeId,
            SelectedStorageSiteId: storageSiteId,
            HideLocationAndContainerTypeDropdowns: hideDropdowns,
            Scanning: false,
        });

        if (containerTypeId !== undefined && storageSiteId !== undefined) {
            const containers = await BackendApi.GetStoragesByTypeAndSiteId(containerTypeId, storageSiteId);
            this.setState({
                Containers: containers,
                Racks: null,
                Boxes: [],
                Samples: null,
                SelectedBox: undefined,
                SelectedRackId: undefined,
                SelectedBoxId: undefined,
                SelectedContainerId: undefined,
                SelectedContainerTypeId: containerTypeId,
                SelectedStorageSiteId: storageSiteId,
                HideLocationAndContainerTypeDropdowns: hideDropdowns,
            });
        } else if (containerTypeId !== undefined) {
            const containers = await BackendApi.GetStoragesByType(containerTypeId);
            this.setState({
                Containers: containers,
                Racks: null,
                Boxes: [],
                Samples: null,
                SelectedBox: undefined,
                SelectedRackId: undefined,
                SelectedBoxId: undefined,
                SelectedContainerId: undefined,
                SelectedContainerTypeId: containerTypeId,
                SelectedStorageSiteId: storageSiteId,
                HideLocationAndContainerTypeDropdowns: hideDropdowns,
            });
        } else if (storageSiteId !== undefined) {
            const containers = await BackendApi.GetStoragesBySite(storageSiteId);
            this.setState({
                Containers: containers,
                Racks: null,
                Boxes: [],
                Samples: null,
                SelectedBox: undefined,
                SelectedRackId: undefined,
                SelectedBoxId: undefined,
                SelectedContainerId: undefined,
                SelectedContainerTypeId: containerTypeId,
                SelectedStorageSiteId: storageSiteId,
                HideLocationAndContainerTypeDropdowns: hideDropdowns,
            });
        } else {
            const containers = await BackendApi.GetStorages();
            this.setState({
                Containers: containers,
                Racks: null,
                Boxes: [],
                Samples: null,
                SelectedBox: undefined,
                SelectedRackId: undefined,
                SelectedBoxId: undefined,
                SelectedContainerId: undefined,
                SelectedContainerTypeId: containerTypeId,
                SelectedStorageSiteId: storageSiteId,
                HideLocationAndContainerTypeDropdowns: hideDropdowns,
            });
        }
    };

    public containerChangedHandler = (value: string): void => {
        this.setState({ Racks: null, Boxes: [], Samples: null, SelectedBox: undefined, SelectedRackId: undefined, SelectedBoxId: undefined, SelectedContainerId: value });
        if (value && value != '-1') {
            this.GetRacksForContainer(+value);
        }
    };
    public rackChangedHandler = (value: string): void => {
        this.setState({ Boxes: [], Samples: null, SelectedBox: undefined, SelectedRackId: value as string, SelectedBoxId: undefined });
        if (value && value != '-1') {
            this.GetBoxesForRack(+value);
        }
    };
    public boxChangedHandler = (value: string): void => {
        let selectedContainer = this.state.Containers.filter((x) => x.id === +this.state.SelectedContainerId!)[0];
        let selectedRack = this.state.Racks!.filter((x) => x.id === +this.state.SelectedRackId!)[0];
        let selectedBox = this.state.Boxes.filter((x) => x.id == +value)[0];
        this.setState({ Samples: null, SelectedBox: selectedBox, SelectedBoxId: value });
        if (value && value != '-1') {
            this.GetSamplesForBox(selectedContainer.name, selectedRack.name, selectedBox.name);
        }
    };
    public HideLocationAndContainerTypeDropdownsToggleHandler = () => {
        this.setState({ HideLocationAndContainerTypeDropdowns: !this.state.HideLocationAndContainerTypeDropdowns });
    };

    public boxSizeChangedHandler = (value: string): void => {
        let currentSelectedBox = { ...this.state.SelectedBox! };
        currentSelectedBox.maxSamples = +value;

        let boxes = [...this.state.Boxes];

        let box = this.state.Boxes.filter((x) => x.id === currentSelectedBox.id)[0];
        let index = boxes.indexOf(box);
        if (boxes) {
            boxes[index] = currentSelectedBox;
        }

        this.setState({ SelectedBox: currentSelectedBox, Boxes: boxes });
        this.SetBoxSize(currentSelectedBox.id!, currentSelectedBox.maxSamples).catch((error) => {
            console.log(error);
        });
    };
    private GetStorageSites() {
        BackendApi.GetStorageSites().then((storageSites) => {
            this.setState({ StorageSites: storageSites });
        });
    }
    private GetContainerTypes() {
        BackendApi.GetStorageTypes().then((response) => {
            this.setState({ ContainerTypes: response });
        });
    }

    private GetRacksForContainer(id: number) {
        BackendApi.GetRacksForContainer(id).then((response) => {
            this.setState({ Racks: response });
        });
    }
    private GetBoxesForRack(id: number) {
        BackendApi.GetBoxesForRack(id).then((response) => {
            this.setState({ Boxes: response });
        });
    }
    private async GetSamplesForBox(containerName: string, rackName: string, boxName: string): Promise<void> {
        const result = await BackendApi.GetSamplesForBox(containerName, rackName, boxName);

        this.setState({ Samples: result });
    }

    private SetBoxSize(id: number, boxSize: number) {
        return BackendApi.SetBoxSize(id, boxSize);
    }

    public render(): React.ReactElement {
        let containers, racks, boxes, samples, storageSites, containerTypes: Array<any> | undefined;
        let hasDrawers = false;
        const allowReading = useApplicationStore.getState().allowReading;
        const allowScanning = useApplicationStore.getState().allowScanning;
        
        const isFFPE =
            this.state &&
            (this.state.SelectedContainerTypeId === 3 ||
                (this.state.SelectedContainerId !== undefined && this.state.Containers.find((x) => x.id === +this.state.SelectedContainerId!)?.containerTypeId === 3));

        if (this.state && this.state.ContainerTypes) {
            containerTypes = this.state.ContainerTypes.map((containerType: ContainerType) => {
                return { value: containerType.id.toString(), label: containerType.name };
            });
        }
        if (this.state && this.state.StorageSites) {
            storageSites = this.state.StorageSites.map((storageSite: StorageSite) => {
                return { value: storageSite.id.toString(), label: storageSite.location };
            });
        }
        if (this.state && this.state.Containers) {
            containers = this.state.Containers.map((container: Container) => {
                return { value: container.id.toString(), label: container.name };
            });
        }
        if (this.state && this.state.Racks) {
            racks = this.state.Racks.map((rack: Rack) => {
                return { value: rack.id.toString(), label: rack.name, key: rack.id.toString() };
            });
            hasDrawers = isFFPE && racks.length > 0;
        }
        if (this.state && this.state.Boxes) {
            boxes = this.state.Boxes.map((box: Box) => {
                return { value: box.id.toString(), label: box.name + ' (' + (box.maxSamples - box.sampleCount) + ')' };
            });
        }
        if (this.state && this.state.Samples && this.state.SelectedBox && !this.state.Scanning && allowReading) {
            samples = <SampleGrid BoxSize={this.state.SelectedBox.maxSamples} Samples={this.state.Samples} />;
        } else if (
            this.state &&
            allowScanning &&
            this.state.SelectedContainerId &&
            ((this.state.Samples && this.state.SelectedBox && this.state.Scanning) || (isFFPE && !hasDrawers) || (isFFPE && hasDrawers && this.state.SelectedRackId))
        ) {
            const selectedContainer = this.state.Containers.find((x) => x.id === +this.state.SelectedContainerId!);
            samples = (
                <SampleScanningGrid
                    ContainerType={this.state.ContainerTypes.find((x) => x.id === selectedContainer!.containerTypeId)?.name!}
                    ContainerName={selectedContainer?.name!}
                    Rack={this.state.SelectedRackId !== undefined ? this.state.Racks!.find((x) => x.id === +this.state.SelectedRackId!)?.name : undefined}
                    Box={this.state.SelectedBoxId !== undefined ? this.state.Boxes.find((x) => x.id === +this.state.SelectedBoxId!)?.name : undefined}
                    BoxSize={this.state && this.state.Samples && this.state.SelectedBox ? this.state.SelectedBox.maxSamples : 1}
                />
            );
        }
        return (
            <div style={{ marginTop: '1em' }}>
                <div>
                    <span style={{ float: 'left' }}>
                        {this.state && this.state.HideLocationAndContainerTypeDropdowns ? (
                            <EyeInvisibleOutlined style={{ fontSize: '24px' }} onClick={this.HideLocationAndContainerTypeDropdownsToggleHandler} />
                        ) : null}
                        {!this.state || !this.state.HideLocationAndContainerTypeDropdowns ? (
                            <EyeOutlined style={{ fontSize: '24px' }} onClick={this.HideLocationAndContainerTypeDropdownsToggleHandler} />
                        ) : null}
                    </span>
                    {this.state && (this.state.SelectedStorageSiteId === undefined || this.state.HideLocationAndContainerTypeDropdowns) ? (
                        <div className={styles.storageSiteSelection}>
                            <Select
                                dropdownMatchSelectWidth={200}
                                placeholder="Select a site"
                                value={this.state && this.state.SelectedStorageSiteId ? this.state.SelectedStorageSiteId.toString() : null}
                                onChange={this.storageSiteChangedHandler}
                                options={storageSites}
                            />
                        </div>
                    ) : null}
                    {this.state && this.state.HideLocationAndContainerTypeDropdowns ? (
                        <div className={styles.containerTypeSelection}>
                            <Select
                                dropdownMatchSelectWidth={100}
                                placeholder="Select a type"
                                value={this.state && this.state.SelectedContainerTypeId ? this.state.SelectedContainerTypeId.toString() : null}
                                onChange={this.containerTypeChangedHandler}
                                options={containerTypes}
                            />
                        </div>
                    ) : null}
                    <div className={styles.containerSelection}>
                        <Select
                            placeholder="Select a container"
                            dropdownMatchSelectWidth={150}
                            value={this.state ? this.state.SelectedContainerId : null}
                            onChange={this.containerChangedHandler}
                            options={containers}
                        />
                    </div>

                    {
                        // Only show rack selection if the container is not a FFPE container or the FFPE container has Drawers setup
                        this.state && (!isFFPE || (isFFPE && hasDrawers)) && (
                            <div className={styles.rackSelection}>
                                <Select
                                    placeholder="Select a rack"
                                    dropdownMatchSelectWidth={100}
                                    value={this.state ? this.state.SelectedRackId : null}
                                    onChange={this.rackChangedHandler}
                                    options={racks}
                                />
                            </div>
                        )
                    }

                    {
                        // Only show box selection if the container is not a FFPE container
                        this.state && !isFFPE && (
                            <div className={styles.boxSelection}>
                                <Select
                                    placeholder="Select a box"
                                    dropdownMatchSelectWidth={100}
                                    value={this.state ? this.state.SelectedBoxId : null}
                                    onChange={this.boxChangedHandler}
                                    options={boxes}
                                />
                            </div>
                        )
                    }

                    {this.state && this.state.SelectedBox && allowReading ? (
                        <div className={styles.boxSizeSelection}>
                            <Select
                                placeholder="Select size"
                                dropdownMatchSelectWidth={100}
                                value={this.state.SelectedBox.maxSamples.toString()}
                                onChange={this.boxSizeChangedHandler}
                                options={[
                                    { value: 36, label: '36' },
                                    { value: 81, label: '81' },
                                    { value: 100, label: '100' },
                                ]}
                            />
                        </div>
                    ) : null}
                    {this.state && this.state.SelectedBox && allowScanning ? (
                        <div className={styles.boxSizeSelection}>
                            <Checkbox checked={this.state.Scanning} onChange={() => this.setState({ Scanning: !this.state.Scanning })}>
                                Scanning
                            </Checkbox>
                        </div>
                    ) : null}
                    <div style={{ clear: 'both' }}></div>
                    <div className={styles.SamplesGrid}>{samples}</div>
                </div>
            </div>
        );
    }
}
