import * as React from 'react';
import { ISampleScanningGridProps } from './ISampleScanningGridProps';
import styles from './SampleScanningGrid.module.scss';
import TextArea from 'antd/es/input/TextArea';
import { ChangeEvent } from 'react';
import { Button } from 'antd';
import { js2xml } from 'xml-js';

export const SampleScanningGrid: React.FunctionComponent<ISampleScanningGridProps> = (props: ISampleScanningGridProps) => {
    const [samples, setSamples] = React.useState<{ [key: string]: string }>({});
    const rows = props.ContainerType !== 'FFPE' ? Math.sqrt(props.BoxSize) : (Object.keys(samples).length + 1) / 10;
    const columns = props.ContainerType !== 'FFPE' ? Math.sqrt(props.BoxSize) : (Object.keys(samples).length + 1) % 10;
    const isFFPE = props.ContainerType === 'FFPE';

    React.useEffect(() => {
        setSamples({});
    }, [props.ContainerName, props.Rack, props.Box]);

    let items = [];

    function textAreaOnChange(position: string, event: ChangeEvent<HTMLTextAreaElement>): void {
        const newSamples = { ...samples, [position]: event.target.value };
        setSamples(newSamples);
    }
    function textAreaOnBlur(position: string, event: React.FocusEvent<HTMLTextAreaElement, Element>): void {
        const newSamples = { ...samples, [position]: event.target.value };
        const duplicates = Object.keys(newSamples).filter(x => newSamples[x] === event.target.value && x !== position)
        if (duplicates.length > 0) {
            alert(`Duplicate sample id "${event.target.value}" at positions ${duplicates.join(', ')}`)
        }
    }

    function positionToXMLPosition(position: string): string {
        const row = position.charCodeAt(0) - 64;
        const adjustedRow = row > 8 ? row - 1 : row;
        return `Y${adjustedRow};X${position.substring(1)}`;
    }

    function getFormatedDateForXML(): string {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hour = String(now.getHours()).padStart(2, '0');
        const minute = String(now.getMinutes()).padStart(2, '0');
        const second = String(now.getSeconds()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        return formattedDate;
    }

    function getFileNameDate(): string {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const formattedDate = `${day}${month}${year}`;
        return formattedDate;
    }

    function handleDownload(): void {
        const records = Object.keys(samples).map((key) => {
            return {
                sample: samples[key].trim(),
                branch: '001',
                sa: 1,
                voucherdate: getFormatedDateForXML(),
                container1: props.ContainerType === 'Freezer' ? 'freezer' : props.ContainerType === 'Nitrogen' ? 'cryo' : 'ffpe_cabinet',
                container2: isFFPE ? props.ContainerName + (props.Rack ? `;${props.Rack}` : '') : props.ContainerName,
                container3: isFFPE ? undefined : props.Rack,
                container4: isFFPE ? undefined : props.Box,
                position: isFFPE ? undefined: positionToXMLPosition(key),
                subsamples: isFFPE ? '0' : '100',
            };
        });
        const data = {
            _declaration: {
                _attributes: {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            recordset: {
                _attributes: {
                    'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
                    'xmlns:xsd': 'http://www.w3.org/2001/XMLSchema',
                },
                record: records,
            },
        };
        const xml = js2xml(data, { compact: true, ignoreComment: true, spaces: 2 });
        const blob = new Blob([xml], { type: 'text/xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${props.ContainerName}_${props.Rack}_${props.Box}_${getFileNameDate()}.xml`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
    const prefix = (props.ContainerName ?? '') + (props.Rack ?? '') + (props.Box ?? '');
    if (props.ContainerType !== 'FFPE') {
        for (let r = 0; r < rows; r++) {
            let rowLetter = String.fromCharCode(65 + (r > 7 ? r + 1 : r));
            for (let c = 0; c < columns; c++) {
                items.push(
                    <div key={prefix + rowLetter + (c + 1)} className={styles.gridItem}>
                        {rowLetter + (c + 1)}
                        <TextArea
                            className={styles.sampleId}
                            rows={2}
                            key={prefix + rowLetter + (c + 1)}
                            style={{ width: '70px', overflow: 'none', padding: '0px' }}
                            onChange={(ev) => textAreaOnChange(rowLetter + (c + 1), ev)}
                            onBlur={(ev) => textAreaOnBlur(rowLetter + (c + 1), ev)}
                        ></TextArea>
                    </div>
                );
            }
        }
    } else {
        for (let x = 0; x < Object.keys(samples).length + 1; x++) {
            items.push(
                <div key={prefix + x} className={styles.gridItem}>
                    #{x}
                    <TextArea
                        className={styles.sampleId}
                        rows={2}
                        key={prefix + x}
                        style={{ width: '70px', overflow: 'none', padding: '0px' }}
                        onChange={(ev) => textAreaOnChange(x + '', ev)}
                    ></TextArea>
                </div>
            );
        }
    }

    let className = '';
    if (props.ContainerType !== 'FFPE') {
        switch (props.BoxSize) {
            case 100:
                className = styles.gridScanningContainer100;
                break;
            case 81:
                className = styles.gridScanningContainer81;
                break;
            case 36:
                className = styles.gridScanningContainer36;
                break;
        }
    } else {
        className = styles.gridScanningFFPE;
    }
    return (
        <>
            <div className={className}>{items}</div>
            <Button style={{ marginTop: '1rem' }} type="primary" onClick={handleDownload}>
                Download
            </Button>
        </>
    );
};
