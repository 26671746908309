import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { ApplicationState } from '../models/stores/ApplicationState';
import BackendApi from '../services/Api';
import AuthHelper from '../services/AuthHelper';

export const useApplicationStore = create<ApplicationState>()(
    devtools((set, get) => ({
        authToken: undefined,
        allowScanning: false,
        allowReading: false,
        login: async (username: string, password: string) => {
            const response = await BackendApi.Login(username, password);
            const allowScanning = AuthHelper.hasRole('SAMPLE_INVENTORY_SCANNING', response);
            const allowReading = AuthHelper.hasRole('SAMPLE_INVENTORY_READER', response);
            
            set({ authToken: response, allowScanning, allowReading });
        },
    }))
);
