import jwtDecode from 'jwt-decode';

class authHelper {
    hasRole(roleName: string, token: string): boolean {
        const decodedToken: any = jwtDecode(token);
        if (!decodedToken || !decodedToken.authorities) {
            throw new Error('Invalid token');
        }
        const authorities = decodedToken.authorities;
        if (!Array.isArray(authorities)) {
            throw new Error('Invalid authorities claim');
        }

        return authorities.some((authority: string) => authority === roleName);
    }
}

const AuthHelper = Object.freeze(new authHelper());
export default AuthHelper;
