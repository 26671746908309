import { Route, Routes } from 'react-router-dom';
import './App.css';
import SampleInventory from './components/SampleInventory';
import LoginPage from './components/Login/LoginPage';
import { useApplicationStore } from './stores/Application';

function App() {
    const token = useApplicationStore((state) => state.authToken);

    return token ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SampleInventory />
        </div>
    ) : (
        <div className="loginPage" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <LoginPage />
        </div>
    );
}

export default App;
