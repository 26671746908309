import React, { useState } from 'react';
import { Button, Card, Form, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../../assets/logo.png';
import { useApplicationStore } from '../../stores/Application';

interface LoginPayload {
    username: string;
    password: string;
}

const LoginPage: React.FC<{}> = (props): React.ReactElement => {
    const { Title } = Typography;
    const history = useNavigate();
    const login = useApplicationStore((state) => state.login);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [logginError, setLoginError] = useState<string | undefined>(undefined);

    const onLoginSubmit = async (values: LoginPayload): Promise<void> => {
        try {
            setIsLoggingIn(true);
            setLoginError(undefined);
            const { username, password } = values;
            await login(username, password);
        } catch (err) {
            console.log(err);
            setLoginError('Invalid username or password!');
        } finally {
            setIsLoggingIn(false);
        }
    };

    return (
        <div>
            <Card>
                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <Title level={2}>
                        <img src={logo} style={{ width: '200px' }} />
                    </Title>
                </div>
                <Form name="login" onFinish={onLoginSubmit}>
                    <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                        <Input prefix={<UserOutlined />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                        <div>
                            <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                            <a style={{ float: 'right' }} href={`${process.env.REACT_APP_AUTH_URL}/forgot-password`}>
                                Forgot password
                            </a>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <div>
                            <Button loading={isLoggingIn} type="primary" htmlType="submit" className="login-form-button" block>
                                Log in
                            </Button>
                            {logginError !== undefined ? (
                                <div className="ant-form-item-explain-error " style={{ textAlign: 'center', marginTop: '0.5rem', fontWeight: 'bold' }}>
                                    {logginError}
                                </div>
                            ) : (
                                <div className="ant-form-item-explain-error " style={{ textAlign: 'center', marginTop: '0.5rem', fontWeight: 'bold' }}>
                                    &nbsp;
                                </div>
                            )}
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginPage;
